<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >

      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="12">
            <b-card>
                <b-form @submit.prevent="create">
                  <b-row>
                    <!-- code -->
                    <b-col cols="6">
                        <b-form-group
                            :label="$t('Code')"
                            label-for="v-code"
                        >
                        <b-form-input
                            id="v-code"
                            :placeholder="$t('Code')"
                            v-model="coupon.code"
                        />
                        </b-form-group>
                    </b-col>
                    <!-- title -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Title')"
                        label-for="v-title"
                      >
                        <b-form-input
                          id="v-title"
                          :placeholder="$t('Title')"
                          v-model="coupon.title"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- amount -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Amount')"
                        label-for="v-amount"
                      >
                        <b-form-input
                          id="v-amount"
                          :placeholder="$t('Amount')"
                          v-model="coupon.amount"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- type -->
                    <b-col cols="6">
                      <b-form-group
                            :label="$t('Type')"
                        label-for="v-types"
                      >
                        <b-form-select
                          id="v-type"
                          v-model="coupon.type"
                          :options="types"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- calc -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Calc')"
                        label-for="v-calcs"
                      >
                        <b-form-select
                          id="v-type"
                          v-model="coupon.calc"
                          :options="calcs"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Couponable type -->
                    <!-- <b-col cols="6" v-if="coupon.type == 'product'">
                      <b-form-group
                        :label="$t('Couponable type')"
                        label-for="v-couponable_type"
                      >
                        <b-form-select
                          id="v-type"
                          v-model="coupon.couponable_type"
                          :options="couponable_types"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col cols="6" v-if="coupon.type == 'product'">
                      <b-form-group
                        :label="$t('Products')"
                        label-for="v-products"
                      >
                        <v-select
                            v-model="products_selected"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="products"
                            multiple
                        >
                            <template #option="{ name, largeThumbnail }">
                                <b-img :src="largeThumbnail" width="50" height="50" />
                                <span> {{ name }}</span>
                            </template>
                        </v-select>
                        <b-button 
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="success"
                          class="mr-1"
                          size="sm"
                          @click="selectProductsNotHasDiscount"
                        >
                          اختر جميع المنتجات الغير مخفضة
                        </b-button>
                      </b-form-group>
                    </b-col>
                    <!-- minimum -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Minimum')"
                        label-for="v-minimum"
                      >
                        <b-form-input
                          id="v-minimum"
                          :placeholder="$t('Minimum')"
                          v-model="coupon.minimum"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- usage limit -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Usage limit')"
                        label-for="v-usage_limit"
                      >
                        <b-form-input
                          id="v-usage_limit"
                          :placeholder="$t('Usage limit')"
                          v-model="coupon.usage_limit"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- start date -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Start date')"
                        label-for="v-start_date"
                      >
                      <flat-pickr
                          v-model="coupon.start_date"
                          class="form-control"
                          :config="flatPickrConfig"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- end date -->
                    
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('End date')"
                        label-for="v-end_date"
                      >
                      <flat-pickr
                          v-model="coupon.end_date"
                          class="form-control"
                          :config="flatPickrConfig"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import {Arabic} from 'flatpickr/dist/l10n/ar.js';

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
    flatPickr,
  },
  name: 'CouponEdit',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      coupon: {},
      snowOption: {},
      products: [],
      products_selected: [],
      types: [
        { value: 'order', text: 'تطبيق على الطلب' },
        { value: 'product', text: 'تطبيق على المنتج' },
      ],
      calcs: [
        { value: 'fixed', text: 'مبلغ ثابت' },
        { value: 'percent', text: 'نسبة مئوية' },
      ],
      couponable_types: [
        { value: 'category', text: 'قسم' },
        { value: 'product', text: 'منتج' },
      ],
      flatPickrConfig: {
          altFormat: 'j F Y',
          altInput: true,
          dateFormat: 'Y-m-d',
          locale: Arabic,    
      },
    }
  },
  mounted() {
      this.getProducts();
  },
  methods: {
    async getProducts() {
        await useJwt.get('/products/get-all')
        .then((response) => {
            this.products = response.data.data
        })
        .catch(response => {
            // console.log(response);
        });
    },
    selectProductsNotHasDiscount() {  

      this.products.forEach(product => {
        if (product.price_after_discount == null) {
          this.products_selected.push(product);
        }
      });

    },
    create() {
      this.isLoadingSave = true;

      if (this.products_selected.length) {
        this.coupon.product_ids = []
        this.products_selected.forEach(product => {
          this.coupon.product_ids.push(product.id);
        });
      }

      // console.log(this.coupon.product_ids)
      // return;

      useJwt.post('/coupons/create',this.coupon)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$router.push({ name: 'coupons' })
      })
      .catch(error => {
        this.isLoadingSave = false
        let error_msg = ''
        if(error.response.data.message.code != undefined) {
            error_msg+=error.response.data.message.code
        }
        if(error.response.data.message.title != undefined) {
            error_msg+=error.response.data.message.title
        }
        if(error.response.data.message.amount != undefined) {
            error_msg+=error.response.data.message.amount
        }
        if(error.response.data.message.type != undefined) {
            error_msg+=error.response.data.message.type
        }
        if(error.response.data.message.calc != undefined) {
            error_msg+=error.response.data.message.calc
        }
        if(error.response.data.message.minimum != undefined) {
            error_msg+=error.response.data.message.minimum
        }
        if(error.response.data.message.usage_limit != undefined) {
            error_msg+=error.response.data.message.usage_limit
        }
        this.$root.showAlert('error', error_msg)
      });
    }
  }
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .ql-editor {
      height: 250px;
    }
</style>
